import { datadogRum } from "@datadog/browser-rum";
import Footer from "components/general/footer";
import MobileMenuWrapper from "components/nav/mobileMenuWrapper";
import Navbar from "components/nav/navbar";
import Banner from "components/banner/banner";
import { ThemeProvider } from "components/services/themeContext";
import useUTM from "hooks/useUTM";
import { useRouter } from "next/router";
import posthog from "posthog-js";
import { PostHogProvider } from "posthog-js/react";
import { useEffect, useState } from "react";
import { UserProvider } from "src/context/user";
import useAffiliate from "src/hooks/useAffiliate";
import { isDev } from "src/lib/config";
import styled from "styled-components";
import "styles/globals.css";
import { CookieConsentPopup } from "../components/cookie-consent/cookieConsentPopup";
import { CookieConsentProvider } from "src/components/cookie-consent/cookieConsentProvider";
import { CookieWidget } from "../components/cookie-consent/cookieWidget";
import MdxLayout from "src/components/aioptout/mdx-layout/mdxlayout";
import { usePathname } from "next/navigation";

const StyledWrapper = styled.div`
  min-height: 100%;
  margin-bottom: -140px;
  padding-bottom: 140px;
  @media screen and (max-width: 992px) {
    margin-bottom: 0;
    padding-bottom: 0;
  }
`;

if (typeof window !== "undefined") {
  const shouldDownsampleRum = () => {
    const downsampleTimezones = ["Asia/Jakarta", "Asia/Jayapura"];
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    if (
      downsampleTimezones.includes(timeZone) ||
      timeZone.startsWith("Asia/")
    ) {
      console.log(`Downsampling timezone: ${timeZone}`);
      return true;
    }
    return false;
  };

  const isDownsampled = shouldDownsampleRum();
  datadogRum.init({
    applicationId: "9fe29478-816a-4024-92bd-b15c06c674cf",
    clientToken: "pub2b0d691f6ab86beb61d9da8bc33f5c3a",
    service: "redact-web",
    env: isDev() ? "development" : "production",
    site: "us5.datadoghq.com",
    proxy: "https://stats.redact.dev",
    // TODO: Add version based on environment variable that gets generated each build, along with sourcemap generation
    version: process.env.NEXT_PUBLIC_RELEASE_VERSION || undefined,
    sessionSampleRate: isDownsampled ? 1 : 20,
    sessionReplaySampleRate: 0, // if not included, the default is 100
    trackResources: true,
    trackLongTasks: true,
    trackUserInteractions: true,
    defaultPrivacyLevel: "mask-user-input",
    trackingConsent: "not-granted",
    actionNameAttribute: "data-action-name",
  });

  datadogRum.setGlobalContextProperty(
    "timezone",
    Intl.DateTimeFormat().resolvedOptions().timeZone,
  );
  datadogRum.setGlobalContextProperty("isDownsampled", isDownsampled);

  posthog.init("phc_bfPd3zipdIE2yZX3D5Dg3zBzdjfCLetcPK1eQT6dqVc", {
    api_host: "https://pig.redact.dev",
    ui_host: "https://us.posthog.com",
    disable_session_recording: true,
    person_profiles: "always",
    loaded: (posthog) => {
      if (isDev()) posthog.debug();
    },
  });
}

export default function Root({ Component, pageProps }) {
  const pagePath = usePathname().split("/");
  const [dark, setDark] = useState(false);

  return (
    <ThemeProvider>
      <PostHogProvider>
        <CookieConsentProvider>
          <StyledWrapper>
            <UserProvider>
              <App>
                <Banner dark={dark} />
                <Navbar dark={dark} />
                {pagePath[1] === "ai-optout-instructions" ? (
                  <MdxLayout>
                    <Component {...pageProps} setDark={setDark} />
                  </MdxLayout>
                ) : (
                  <Component {...pageProps} setDark={setDark} />
                )}
                <MobileMenuWrapper />
              </App>
            </UserProvider>
          </StyledWrapper>
          <Footer />
          <CookieConsentPopup />
          <CookieWidget />
        </CookieConsentProvider>
      </PostHogProvider>
    </ThemeProvider>
  );
}

const App = ({ children }) => {
  const router = useRouter();
  useUTM();
  useAffiliate();

  useEffect(() => {
    const handleRouteChange = (url, { shallow }) => {
      posthog?.capture("$pageview");
    };

    router.events.on("routeChangeComplete", handleRouteChange);

    // If the component is unmounted, unsubscribe
    // from the event with the `off` method:
    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, [router]);

  return <>{children}</>;
};
