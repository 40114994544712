import React from "react";
import Button from "../general/buttonv2";
import { useCollapse } from "react-collapsed";
import Switch from "../general/switch";
import { Bold } from "../general/util";
import { CookieType, useCookieConsent } from "./cookieConsentProvider";
import { renderIf } from "../../util";
import Link from "next/link";

interface PreferencesProps {
  containerProps: React.HTMLProps<HTMLDivElement>;
}

const CollapsibleInfoPanel = ({
  title,
  prefKey,
  children,
  defaultToggle,
}: {
  title: string;
  children: React.ReactNode;
  prefKey?: CookieType;
  defaultToggle?: boolean;
}) => {
  const { updatePrefs, preferences } = useCookieConsent();
  const { getCollapseProps, getToggleProps, isExpanded } = useCollapse({
    defaultExpanded: false,
  });

  const icon = isExpanded ? "ic--baseline-minus" : "ic--baseline-plus";

  return (
    <div className="rounded-lg bg-brand-duskslate my-3">
      <div className="text-base">
        <div className="flex flex-row items-center" {...getToggleProps()}>
          <span className={`px-8 icon-[${icon}] h-[18px] w-[18px]`} />
          <h1 className="text-lg p-4 pl-0 font-semibold m-0">{title}</h1>
          {renderIf(prefKey, (key) => (
            <div className="ml-auto pr-4">
              <Switch
                defaultValue={defaultToggle}
                id={`toggle-${title}`}
                onToggle={(toggled) => updatePrefs(key, toggled)}
                value={preferences[key]}
              />
            </div>
          ))}
        </div>
        <div {...getCollapseProps()}>
          <div className="px-16 pb-4">{children}</div>
        </div>
      </div>
    </div>
  );
};

const Preferences = ({ containerProps }: PreferencesProps) => {
  const { essentialOnly } = useCookieConsent();

  return (
    <div {...containerProps}>
      <h1 className="text-lg">Cookie preferences</h1>
      <CollapsibleInfoPanel title={"Functional Cookies (Necessary)"}>
        <p className="m-0">
          These cookies are essential for the website to function and cannot be switched off in our
          systems.
        </p>
        Examples: Login authentication, shopping cart functionality.
      </CollapsibleInfoPanel>
      <CollapsibleInfoPanel prefKey="analytics" title={"Analytics Cookies"}>
        <p className="m-0">
          These cookies help us measure and improve the performance of our site by collecting
          anonymous data.
        </p>
        Examples: Remembering language preferences.
      </CollapsibleInfoPanel>
      <CollapsibleInfoPanel prefKey="marketing" title={"Marketing Cookies"}>
        <p className="m-0">
          These cookies are used to deliver personalized ads and track your browsing activity across
          websites.
        </p>
        Examples: Tracking page load times
      </CollapsibleInfoPanel>
      <CollapsibleInfoPanel prefKey="preferences" title={"Preferences Cookies"}>
        <p className="m-0">
          These cookies remember your settings, like language or layout, to provide a personalized
          and seamless experience.
        </p>
        Examples: Remembering theme preferences.
      </CollapsibleInfoPanel>
      {/* button below is invisible on larger screens */}
      <Button
        noSparkle={true}
        wrapperClass="md:hidden block"
        className="w-full md:w-auto mb-2 mt-4 md:my-0"
        style={{ display: "block" }}
        $accent="#eee"
        onClick={essentialOnly}>
        Essential only
      </Button>
    </div>
  );
};

export const CookieConsentPopup = () => {
  const { getCollapseProps, getToggleProps, isExpanded } = useCollapse({
    defaultExpanded: false,
  });

  const {
    preferences,
    savePrefs,
    essentialOnly,
    hasSetConsent,
    touched,
    showBanner,
    setShowBanner,
  } = useCookieConsent();

  const hasAllAccepted = preferences.analytics && preferences.marketing && preferences.preferences;

  const acceptSettingsButtonText = hasAllAccepted ? "Accept all" : "Accept selected";
  const managePreferencesButtonText = isExpanded ? "Hide preferences" : "Manage preferences";

  const { ref, ...togleProps } = getToggleProps();

  if (!showBanner) {
    return null;
  }

  return (
    <div
      className={`fixed w-full flex flex-col sm:flex-row items-center justify-between z-50 bottom-[20px] md:bottom-[40px] z-[101]`}>
      <div className="w-full md:w-3/4 mx-auto">
        <div className="w-[90%] flex m-auto border-brand-baby-blue p-[30px] border-t-[18px] pt-[20px] flex-col bg-brand-slate shadow-lg rounded-lg m-auto">
          <p className="text-sm sm:text-base mb-0 sm:mb-0">
            We use <Link href="https://redact.dev/cookies">cookies</Link> to give you the best
            online experience. Strictly necessary cookies are <Bold>on</Bold> by default. Additional
            cookies are off by default. See our privacy policy{" "}
            <Link href="https://redact.dev/privacy">here</Link>
          </p>
          <Preferences containerProps={getCollapseProps()} />
          <div className="flex w-full mt-3 md:mb-0 items-stretch [&>div]:w-[calc(50%-5px)] md:[&>div]:w-auto [&_button]:text-nowrap [&_button]:block">
            <Button
              noSparkle={true}
              className="w-full md:w-auto mr-[10px]"
              onClick={() => savePrefs()}
              disabled={!touched && hasSetConsent}>
              {acceptSettingsButtonText}
            </Button>
            {/* button below is invisible on smaller screens */}
            <Button
              noSparkle={true}
              className="w-full md:w-auto mb-2 mt-4 md:my-0"
              wrapperClass="hidden md:block"
              style={{ display: "block" }}
              $accent="#eee"
              onClick={essentialOnly}>
              Essential only
            </Button>
            <Button
              noSparkle={true}
              className="w-full md:w-auto"
              wrapperClass="ml-auto"
              $accent="#eee"
              {...togleProps}>
              {managePreferencesButtonText}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
