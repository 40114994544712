import * as React from "react";

import { useCallback, useEffect, useState, useContext, useMemo } from "react";
import { Cookiebot, CookiePreferences, WritableCookiePreferences } from "../../types";
import { errorProxyWrapper } from "../../util";
import { useImmer } from "use-immer";
import * as ramda from "ramda";

const normalizeCookiePreferences = (prefs: Cookiebot["consent"]): CookiePreferences => ({
  marketing: prefs.marketing,
  analytics: prefs.statistics,
  preferences: prefs.preferences,
  functional: true,
});

export type CookieType = keyof WritableCookiePreferences;

const cookieConsentError = new Error(
  "Could not find cooke context, is this component a child of <CookieConsentProvider />?",
);

interface CookieConsentContext {
  updatePrefs: (key: CookieType, value: boolean) => void;
  savePrefs: (prefs?: Partial<WritableCookiePreferences>) => void;
  essentialOnly: () => void;
  setShowBanner: (showBanner: boolean) => void;
  preferences: CookiePreferences;
  hasSetConsent: boolean;
  touched: boolean;
  loaded: boolean;
  showBanner: boolean;
}

const CookieConsentContext = React.createContext<CookieConsentContext>(
  errorProxyWrapper({} as CookieConsentContext, cookieConsentError),
);

const defaultPrefs: CookiePreferences = {
  marketing: true,
  analytics: true,
  preferences: true,
  functional: true,
};

export const CookieConsentProvider = ({ children }: { children: React.ReactNode }) => {
  const [showBanner, setShowBanner] = useState(false);
  const [touched, setTouched] = useState(false);
  const [hasSetConsent, setHasSetConsent] = useState(false);
  const [preferences, setPreferences] = useImmer<CookiePreferences>(defaultPrefs);
  const [loaded, setLoaded] = useState(false);

  // ssr sucks
  useEffect(() => {
    let interval: NodeJS.Timeout | undefined;
    let timesTried = 0;

    const checkLoadedHandler = () => {
      if (timesTried > 10) {
        clearInterval(interval);
      }
      if (!window.Cookiebot) {
        timesTried++;
        return;
      }

      const hasSetConsent = window.Cookiebot.consent.stamp !== "0";

      const { gdprApplies, lgpdApplies, ccpaApplies } = window.Cookiebot.regulations;
      const requiresExplicitConsent = gdprApplies || lgpdApplies || ccpaApplies;

      let shouldShow = !hasSetConsent;

      // if the user has not yet set consent, and they are in a region that does not require explicit consent
      // submit implied consent
      const canSubmitImpliedConsent = !requiresExplicitConsent && !hasSetConsent;
      if (canSubmitImpliedConsent) {
        window.Cookiebot.submitCustomConsent(true, true, true);
        shouldShow = false;
      }

      setPreferences(normalizeCookiePreferences(window.Cookiebot.consent));
      setHasSetConsent(hasSetConsent);
      setShowBanner(shouldShow);
      setLoaded(true);
      clearInterval(interval);
    };

    interval = setInterval(checkLoadedHandler, 500);

    return () => {
      clearInterval(interval);
    };
  }, [setPreferences, setHasSetConsent, setShowBanner, loaded, setLoaded]);

  useEffect(() => {
    const onCookiebotUpdateConsent = () => {
      if (!loaded) {
        return;
      }

      setPreferences(normalizeCookiePreferences(window.Cookiebot.consent));
    };

    window.addEventListener("CookiebotOnConsentReady", onCookiebotUpdateConsent);

    return () => {
      window.removeEventListener("CookiebotOnConsentReady", onCookiebotUpdateConsent);
    };
  }, [setPreferences, loaded]);

  const updatePrefs = useCallback(
    (key: CookieType, value: boolean) => {
      setPreferences((draft) => {
        draft[key] = value;
      });
    },
    [setPreferences],
  );

  const isTouched = (saved: CookiePreferences, state: CookiePreferences) => {
    return !ramda.equals(saved, state);
  };

  useEffect(() => {
    if (!window.Cookiebot) {
      return;
    }
    const savedConsent = normalizeCookiePreferences(window.Cookiebot.consent);
    setTouched(isTouched(savedConsent, preferences));
  }, [preferences, setTouched]);

  const savePrefs = useCallback(
    (argPrefs?: Partial<WritableCookiePreferences>) => {
      const prefsToUse = { ...preferences, ...argPrefs };
      const { marketing, analytics, preferences: cookiePref } = prefsToUse;

      setPreferences(prefsToUse);
      setHasSetConsent(true);
      setShowBanner(false);
      window.Cookiebot?.submitCustomConsent(cookiePref, analytics, marketing);
      setTouched(false);
    },
    [setShowBanner, setTouched, preferences, setPreferences],
  );

  const essentialOnly = useCallback(() => {
    savePrefs({
      marketing: false,
      analytics: false,
      preferences: false,
    });
  }, [savePrefs]);

  const ctxValue = useMemo(
    () => ({
      updatePrefs,
      savePrefs,
      preferences,
      touched,
      loaded,
      essentialOnly,
      setShowBanner,
      hasSetConsent,
      showBanner,
    }),
    [
      updatePrefs,
      loaded,
      savePrefs,
      preferences,
      touched,
      setShowBanner,
      essentialOnly,
      hasSetConsent,
      showBanner,
    ],
  );

  return <CookieConsentContext.Provider value={ctxValue}>{children}</CookieConsentContext.Provider>;
};

export const useCookieConsent = () => useContext(CookieConsentContext);
